export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat Password"])},
        "password_placeholer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input new password"])},
        "password2_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please repeat the new password"])},
        "passwordNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password doesnot match."])}
      },
      "zh": {
        "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改密码"])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密 码"])},
        "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复密码"])},
        "password_placeholer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入新密码"])},
        "password2_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请再次输入新密码"])},
        "passwordNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["两次输入的密码不匹配"])}
      }
    }
  })
}
